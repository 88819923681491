<template>
  <div class="w-full xs:w-48 md:w-60 px-1 h-full mb-2">
    <div class="bg-bg_alt_color text-text_color rounded-md h-full p-2 flex flex-col">
      <div class="w-full aspect-[1.3]">
        <img
          v-if="articleLocal.image?.length"
          :src="articleLocal.image"
          :alt="articleLocal.title"
          class="rounded-md mb-3 w-full h-full"
        />
      </div>

      <h2 class="text-xl font-bold mb-2 line-clamp-2">
        {{ articleLocal.title }}
      </h2>
      <p class="text-sm  mb-1 flex-1 line-clamp-3 text-opacity-65">
        {{ articleLocal.summary }}
      </p>
      <EStoreLink
        v-if="articleLocal.id"
        :to="`/articles/${articleLocal.id}`"
        :aria-label="t('aria_page_label', { page: articleLocal.title })"
        class="hover:underline"
      >
        <div class="text-sm font-semibold text-opacity-25">
          {{ t('read_more') }}
        </div>
      </EStoreLink>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{ article: Article }>()
const { articleModel } = useMenuModel()
const articleLocal = articleModel(props.article)
const { t } = useI18n()
</script>
