<template>
  <div>
    <RikazGrid name="articles" :items="articles">
      <template #card="{ forKey, data }">
        <ArticlesCard
          :key="`article-grid-item-${forKey}`"
          class="h-auto"
          :article="(data as Article) "
        />
      </template>
    </RikazGrid>
    <ClientOnly>
      <EStoreInfiniteScroll
        class="my-5"
        :infinite-id="infiniteId"
        @load-more="loadMore"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
import { useToast } from 'vue-toastification'
import getAllRoutes from '~/composables/AppApiRoutes'
import type { Article } from '~~/composables/useMenuModel'

const props = defineProps({
  itemsPerPage: { type: Number, default: 10 }
})
const { store } = useDomainState()
const articles = ref<Article[]>([])
const toast = useToast()

const { t } = useI18n()

const { infiniteId, scrollFetch, loadMore } = useInfiniteScroll(
  0,
  props.itemsPerPage,
  fetchArticles,
  appendArticles,
  clearArticles
)

await scrollFetch()

async function fetchArticles(state: ScrollState) {
  try {
    const { data, error } = await useBasicFetch<ApiResponse<[]>>(
      getAllRoutes().articlesRoutes.getArticles(store.value?.id),
      {
        query: {
          offset: `${state.offset}`,
          limit: `${state.limit}`
        }
      }
    )

    if (error?.value) {
      throw error.value
    }

    return data.value
  } catch (error) {
    if (process.client) {
      toast.error(t('fetch_failed', { data: t('articles') }), { timeout: 1000 })
    }

    if ((error as any)?.data?.data) {
      return (error as any).data as ApiResponse<[]>
    } else {
      return {
        data: [],
        message: t('fetch_failed', { data: t('articles') })
      } as ApiResponse<[]>
    }
  }
}

function appendArticles(data: []) {
  articles.value = [...articles.value, ...data]
}
function clearArticles() {
  articles.value = []
}
</script>
